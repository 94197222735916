<template>
  <div class="step-5-confirmation">
    <h2 class="text-3xl font-bold mb-8 text-center">Confirm Your Booking</h2>
    <div class="booking-details bg-lol-purple bg-opacity-30 p-6 rounded-lg mb-8">
      <h3 class="text-2xl font-bold mb-4">Booking Details</h3>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div class="space-y-3">
          <div class="flex justify-between items-center">
            <span class="font-semibold">Booth Type:</span>
            <span>{{ bookingData.boothType }}</span>
          </div>
          <div class="flex justify-between items-center">
            <span class="font-semibold">Rental Hours:</span>
            <span>{{ bookingData.hours }} hours</span>
          </div>
          <div class="flex justify-between items-center">
            <span class="font-semibold">Booth Price:</span>
            <span>${{ boothPrice }}</span>
          </div>
        </div>
        
        <!-- Display selected backdrop -->
        <div v-if="selectedBackdrop" class="flex items-center space-x-4">
          <img :src="selectedBackdrop.image" :alt="selectedBackdrop.name" class="w-20 h-20 object-cover rounded-lg">
          <div>
            <p class="font-semibold">Selected Backdrop:</p>
            <p>{{ selectedBackdrop.name }}</p>
          </div>
        </div>
      </div>

      <!-- Display selected add-ons with images -->
      <div v-if="selectedAddons.length > 0" class="mt-6">
        <h4 class="text-xl font-bold mb-3">Selected Add-ons:</h4>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div v-for="addon in selectedAddons" :key="addon.id" class="flex items-center space-x-3">
            <img :src="addon.image" :alt="addon.name" class="w-12 h-12 object-cover rounded-lg">
            <div>
              <p class="font-semibold">{{ addon.name }}</p>
              <p class="text-lol-pink font-bold">${{ addon.price }}</p>
            </div>
          </div>
        </div>
        <p class="font-bold mt-3 text-lg">Add-ons Total: ${{ bookingData.addonsTotalPrice }}</p>
      </div>

      <!-- Display total price including GST -->
      <div class="mt-6 bg-lol-pink bg-opacity-20 p-4 rounded-lg">
        <div class="flex justify-between text-lg mb-2">
          <span>Subtotal:</span>
          <span>${{ subtotal }}</span>
        </div>
        <div class="flex justify-between text-lg mb-2">
          <span>GST (10%):</span>
          <span>${{ gst }}</span>
        </div>
        <div class="flex justify-between text-xl font-bold mt-3">
          <span>Total (including GST):</span>
          <span>${{ total }}</span>
        </div>
      </div>
    </div>

    <div class="customer-info bg-lol-purple bg-opacity-30 p-6 rounded-lg">
      <h3 class="text-2xl font-bold mb-4">Your Information</h3>
      <form @submit.prevent="submitBooking" class="space-y-4">
        <div>
          <label for="firstName" class="block mb-1 text-sm">First Name:</label>
          <input type="text" id="firstName" v-model="customerInfo.firstName" required class="w-full p-2 bg-white text-lol-purple rounded-lg text-base">
        </div>
        <div>
          <label for="lastName" class="block mb-1 text-sm">Last Name:</label>
          <input type="text" id="lastName" v-model="customerInfo.lastName" required class="w-full p-2 bg-white text-lol-purple rounded-lg text-base">
        </div>
        <div>
          <label for="mobile" class="block mb-1 text-sm">Mobile:</label>
          <input type="tel" id="mobile" v-model="customerInfo.mobile" required class="w-full p-2 bg-white text-lol-purple rounded-lg text-base">
        </div>
        <div>
          <label for="email" class="block mb-1 text-sm">Email:</label>
          <input type="email" id="email" v-model="customerInfo.email" required class="w-full p-2 bg-white text-lol-purple rounded-lg text-base">
        </div>
        <div>
          <label for="venueName" class="block mb-1 text-sm">Venue Name:</label>
          <input type="text" id="venueName" v-model="customerInfo.venueName" required class="w-full p-2 bg-white text-lol-purple rounded-lg text-base">
        </div>
        <div>
          <label for="venueAddress" class="block mb-1 text-sm">Venue Address:</label>
          <input type="text" id="venueAddress" v-model="customerInfo.venueAddress" required class="w-full p-2 bg-white text-lol-purple rounded-lg text-base">
        </div>
        <div>
          <label for="eventDate" class="block mb-1 text-sm">Event Date:</label>
          <input type="date" id="eventDate" v-model="customerInfo.eventDate" required class="w-full p-2 bg-white text-lol-purple rounded-lg text-base">
        </div>
        <div>
          <label for="eventTime" class="block mb-1 text-sm">Event Time:</label>
          <input type="time" id="eventTime" v-model="customerInfo.eventTime" required class="w-full p-2 bg-white text-lol-purple rounded-lg text-base">
        </div>
        <MathChallenge id="booking-math-challenge" @update:isValid="updateMathChallengeValidity" />
        <div class="md:col-span-2">
          <button 
            type="submit" 
            class="w-full bg-lol-pink hover:bg-opacity-80 text-white font-bold py-3 px-4 rounded-full text-lg mt-4"
            :disabled="!isMathChallengeValid || isSubmitting"
          >
            {{ isSubmitting ? 'Submitting...' : 'Confirm Booking' }}
          </button>
        </div>
      </form>
    </div>

    <!-- 添加一个新的区域来显示提交状态和消息 -->
    <div v-if="submitStatus" class="mt-6 p-4 rounded-lg text-center" :class="{ 'bg-green-500': submitStatus === 'success', 'bg-red-500': submitStatus === 'error' }">
      <p class="text-white font-bold">{{ statusMessage }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MathChallenge from '@/components/MathChallenge.vue'

export default {
  name: 'Step5Confirmation',
  components: {
    MathChallenge
  },
  props: ['bookingData'],
  data() {
    return {
      customerInfo: {
        firstName: '',
        lastName: '',
        mobile: '',
        email: '',
        venueName: '',
        venueAddress: '',
        eventDate: '',
        eventTime: ''
      },
      isSubmitting: false,
      submitStatus: null,
      statusMessage: '',
      addons: [
        { id: 1, name: 'Red Carpet & Golden Pillars', price: 150, image: '/images/addons/addon1.jpeg' },
        { id: 2, name: 'Photobooth Album', price: 75, image: '/images/addons/addon2.jpeg' },
        { id: 3, name: '1-meter LED Lights', price: 350, image: '/images/addons/addon3.jpeg' },
      ],
      backdrops: [
        { id: 1, name: 'Backdrop 1', image: '/images/backdrops/backdrop1.jpeg' },
        { id: 2, name: 'Backdrop 2', image: '/images/backdrops/backdrop2.jpeg' },
        { id: 3, name: 'Backdrop 3', image: '/images/backdrops/backdrop3.jpeg' },
        { id: 4, name: 'Backdrop 4', image: '/images/backdrops/backdrop4.jpeg' },
        { id: 5, name: 'Backdrop 5', image: '/images/backdrops/backdrop5.jpeg' },
        { id: 6, name: 'Backdrop 6', image: '/images/backdrops/backdrop6.jpeg' },
        { id: 7, name: 'Backdrop 7', image: '/images/backdrops/backdrop7.jpeg' },
        { id: 8, name: 'Backdrop 8', image: '/images/backdrops/backdrop8.jpeg' },
      ],
      isMathChallengeValid: false
    }
  },
  computed: {
    boothPrice() {
      if (this.bookingData.boothType === 'Retro Booth') {
        return 1200; // Retro Booth 固定价格
      } else if (this.bookingData.boothType === 'Gold Mirror Booth') {
        return this.bookingData.hours === 3 ? 1050 : 1200; // Gold Mirror Booth 价格
      } else if (this.bookingData.boothType === 'Silver Mirror Booth') {
        return this.bookingData.hours === 3 ? 850 : 1000; // Silver Mirror Booth 价格
      } else if (this.bookingData.boothType === 'Bronze Booth') {
        return this.bookingData.hours === 3 ? 750 : 900; // Bronze Booth 价格
      } else {
        console.error('Unknown booth type:', this.bookingData.boothType);
        return 0; // 未知亭子类型
      }
    },
    selectedAddons() {
      return this.addons.filter(addon => (this.bookingData.addons || []).includes(addon.id));
    },
    selectedBackdrop() {
      return this.backdrops.find(backdrop => backdrop.id === this.bookingData.backdrop);
    },
    subtotal() {
      const addonsTotalPrice = this.bookingData.addonsTotalPrice || 0;
      return this.boothPrice + addonsTotalPrice;
    },
    gst() {
      return Math.round(this.subtotal * 0.1 * 100) / 100;
    },
    total() {
      return this.subtotal + this.gst;
    }
  },
  methods: {
    updateMathChallengeValidity(isValid) {
      this.isMathChallengeValid = isValid
    },
    async submitBooking() {
      if (!this.isMathChallengeValid) {
        this.submitStatus = 'error';
        this.statusMessage = 'Please complete the security check correctly.';
        return;
      }

      this.isSubmitting = true;
      this.submitStatus = null;
      this.statusMessage = '';

      const bookingDetails = {
        ...this.bookingData,
        customerInfo: this.customerInfo,
        subtotal: this.subtotal,
        gst: this.gst,
        total: this.total,
        selectedAddons: this.selectedAddons,
        selectedBackdrop: this.selectedBackdrop,
        boothPrice: this.boothPrice
      };

      try {
        const response = await axios.post('/api/submit-booking', bookingDetails);
        if (response.data.success) {
          this.submitStatus = 'success';
          this.statusMessage = "Your booking has been successfully submitted! We'll contact you soon.";
          setTimeout(() => {
            this.$router.push('/');
          }, 3000);
        } else {
          throw new Error('Submission failed');
        }
      } catch (error) {
        console.error('Error submitting booking:', error);
        this.statusMessage = 'There was an error submitting your booking. Please try again.';
      } finally {
        this.isSubmitting = false;
      }
    }
  }
}
</script>

<style scoped>
.step-5-confirmation {
  @apply max-w-4xl mx-auto px-4;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
